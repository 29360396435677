<template>
  <v-container class="my-5">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-title class="title primary--text font-weight-black">
            Документы курьера
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="t in templates" :key="t.id" cols="12" sm="2">
        <v-icon color="primary"
                @click="createDocument(t.id)">
          mdi-file-document
        </v-icon>
        {{ t.name }}
      </v-col>
    </v-row>

    <v-card v-for="v in list" :key="v.id">
      <v-layout row wrap class="pa-3">
        <v-flex xs3 class="ml-3">
          <div class="caption light-green--text text--darken-3">Название</div>
          <div>{{ v.name }}</div>
        </v-flex>
        <v-flex xs3>
          <div class="d-flex justify-center body-2" v-for="f in v.fields" :key="f.key">
            <div v-if="f.type === 'image'">
              {{ f.label }} :
              <v-img :src="f.value" height="100px" width="100px" dark/>
            </div>
            <div v-else>
              {{ f.label }} : {{ f.value }}
            </div>
          </div>
        </v-flex>
        <v-flex xs3>
          <v-icon color="primary"
                  @click="edit(v)">
            mdi-pencil
          </v-icon>
        </v-flex>
      </v-layout>
      <v-divider/>
    </v-card>
  </v-container>
</template>

<script>
import {uuid} from "uuidv4";

export default {
  data: () => ({}),

  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("drivers/fetchDocument", id);
    this.$store.dispatch("documentTypes/fetch");
  },

  computed: {
    list() {
      return this.$store.getters["drivers/documents"]
    },
    templates() {
      return this.$store.getters["documentTypes/list"]
    }
  },

  methods: {
    createDocument(documentTypeID) {
      let driverID = this.$route.params.id;
      let id = uuid.Nil;
      this.$router.push({
        name: 'driversDocumentEdit',
        params: {id: id, driver_id: driverID, document_type_id: documentTypeID}
      })
    },
    edit(v) {
      let driverID = this.$route.params.id;
      this.$router.push({
        name: 'driversDocumentEdit',
        params: {id: v.id, driver_id: driverID, document_type_id: v.document_type_id}
      })
    }
  }
};
</script>